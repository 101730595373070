<template>
  <div class="table-box">
    <div class="mt-5" v-if="loading">
        <loading-ajax key="tableFocalPoints" v-if="loading"/>
    </div>
    <b-table v-else :items="items" :fields="fields" :tbody-tr-class="setTbodyTrClass">
    </b-table>
  </div>
</template>

<script>
import api from '@/services/api.js'
  export default {
    data() {
        return {
            fields: [
                {
                    key: 'blank1',
                    label: '',
                    thStyle: {
                                width: '10%',
                            }
                },
                {
                    key: 'fullName',
                    label: 'Nome',
                    thStyle: {
                                width: '30%'
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left' 
                },
                {
                    key: 'organizationUnit',
                    label: 'Unidade',
                    thStyle: {
                                width: '20%'
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left activeBorder'  
                },
                {
                    key: 'email',
                    label: 'E-mail',
                    thStyle: {
                                width: '30%'
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left'  
                },
                {
                    key: 'blank2',
                    label: '',
                    thStyle: {
                                width: '10%'
                            }
                }
            ],
            items: [],
            loading: true
        }
    },
    async created() {
        var t = this
        await api.get('/supportTeam').then(response => (
            t.items = response.data.data
        )).finally(() => this.loading = false)  
    },
    methods: {
        setTbodyTrClass(item) {
            const classes = [];
            if(item) classes.push('custom-border');
            return classes;
        }
    }
  }
</script>

<style lang="scss">
    
    @import '@/web/scss/tables.scss'



</style>