<template>
  <div class="ball d-flex align-items-center justify-content-center" :style="bgStyle">
        <div>
          <p class="ball-title">{{title}}</p>  
          <p class="m-0 text">{{text}}</p>
        </div>
      
  </div>
</template>

<script>
export default {
    props: ['title', 'text', 'color'],
    data() {
        return {
            bgStyle: {},
        }
    },
    created() {
        this.bgStyle['background-color'] = this.color;    
    }
}
</script>

<style scoped>
    .ball {
        width: 300px;
        height: 300px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 300px;
        box-sizing: border-box;
        z-index: 1;
    }
    .ball-title {
        font-weight: bold;
    }
    .text {
        font-size: 12px;
        padding: 20px;
        box-sizing: border-box;
    }
    .shadow {
        box-shadow: 0px 40px 40px #aaaaaa60;
    }
@media screen and (max-width: 600px) {
    .ball {
        width: 250px;
        height: 250px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 250px;
        box-sizing: border-box;
        margin-bottom: 2rem;
    }
}
</style>
