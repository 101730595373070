<template>
  <div class="table-box">
    <div class="mt-5" v-if="loading">
        <loading-ajax key="tableProcessCoord" v-if="loading"/>
    </div>
    <b-table v-else responsive :items="items" :fields="fields" :tbody-tr-class="setTbodyTrClass">
        <template #cell(urlCurriculumLattes)="row">
            <button class="cl" @click="openCL(row.item.urlCurriculumLattes)">
                <img :src="image" @mouseover="imageHover" @mouseout="imageOut" alt="">
            </button>
        </template>
        <template #cell(position)="row">
            <div v-html="row.item.position"></div>
        </template>
    </b-table>
  </div>
</template>

<script>
import api from '@/services/api.js'
  export default {
    data() {
        return {
            image: '/img/CL_Button.png',
            clImageHover: '/img/CL_ButtonHover.png',
            fields: [
                {
                    key: 'blank1',
                    label: '',
                    thStyle: {
                                width: '10%',
                            }
                },
                {
                    key: 'fullName',
                    label: 'Nome',
                    thStyle: {
                                width: '30%'
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left' 
                },
                {
                    key: 'position',
                    label: 'Cargo',
                    thStyle: {
                                width: '25%',
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left activeBorder'  
                },
                {
                    key: 'urlCurriculumLattes',
                    label: 'Lattes',
                    thStyle: {
                                width: '10%'
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left activeBorder'  
                },
                {
                    key: 'institutionalEmail',
                    label: 'E-mail',
                    thStyle: {
                                width: '20%'
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left'  
                },
                {
                    key: 'blank2',
                    label: '',
                    thStyle: {
                                width: '10%'
                            }
                }
            ],
            items: [],
            loading: true
        }
    },
    async created() {
        var t = this
        await api.get('/processCoordinationTeam').then(response => (
            t.items = response.data.data
        )).finally(() => this.loading = false)  
    },
    methods: {
        imageHover(e) {
            e.target.src = this.clImageHover
        },
        imageOut(e) {
            e.target.src = this.image
        },
        setTbodyTrClass(item) {
            const classes = [];
            if(item) classes.push('custom-border');
            return classes;
        },
        openCL(url) {
            window.open(url, "_blank"); 
        }
    }
  }
</script>

<style lang="scss">
    
    @import '@/web/scss/tables.scss'



</style>